import { useState, useEffect } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const useCheckBattle = () => {
    const [isInBattle, setIsInBattle] = useState(false);
    const [battleId, setBattleId] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const location = useLocation();

    useEffect(() => {
        console.log("Current location:", location.pathname); // Debug log
        const checkIfInBattle = async () => {
            if (token) {
                const response = await fetch("https://impol.link/api/check-if-i-am-in-battle", {
                    method: 'GET',
                    headers: {
                        'Authorization': token // Include the token in the Authorization header
                    }
                });
                const data = await response.json();
                console.log("Battle check data:", data); // Debug log
                if (data.inBattle) {
                    if (battleId !== data.battleId) {
                        setBattleId(data.battleId);
                        setIsInBattle(true);
                    }
                    // Only redirect if the current path is not the battle route
                    if (!location.pathname.startsWith(`/battle/${data.battleId}`)) {
                        console.log("Redirecting to battle route"); // Debug log
                        navigate(`/battle/${data.battleId}`, { replace: true });
                    }
                } else {
                    setIsInBattle(false);
                    setBattleId(null);
                }
            } else {
                console.error('No token found');
            }
        };

        checkIfInBattle();
        const interval = setInterval(checkIfInBattle, 5000);
        return () => clearInterval(interval);
    }, [navigate, location, token]);

    return { isInBattle, battleId };
};

export default useCheckBattle;