import React, { Component } from 'react';

interface HeroProps {
    name: string;
    power: string;
}

interface HeroState {
    isVisible: boolean;
}

class Hero extends Component<HeroProps, HeroState> {
    constructor(props: HeroProps) {
        super(props);
        this.state = {
            isVisible: true
        };
    }

    toggleVisibility = () => {
        this.setState(prevState => ({
            isVisible: !prevState.isVisible
        }));
    };

    render() {
        const { name, power } = this.props;
        const { isVisible } = this.state;

        return (
            <div>
                <h2>Hero Component</h2>
                {isVisible && (
                    <div>
                        <p>Name: {name}</p>
                        <p>Power: {power}</p>
                    </div>
                )}
                <button onClick={this.toggleVisibility}>
                    {isVisible ? 'Hide' : 'Show'} Details
                </button>
            </div>
        );
    }
}

export default Hero;