import React, { useState, useEffect } from 'react';
import styles from './style/Battle.module.css'; // Import the CSS module

interface LogsProps {
    battleId: string;
    token: string | null;
}
const Logs: React.FC<LogsProps> = ({ battleId, token }) => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState("");

    const fetchLogs = async () => {
        if (token) {
            const response = await fetch(`https://impol.link/api/battle/${battleId}/log`, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            console.log("Fetched logs:", data.logs); // Debug log
            setLogs(data.logs);
        }
    };

    useEffect(() => {
        const loadLogs = async () => {
            try {
                await fetchLogs();
            } catch (error) {
                console.error("Error fetching logs:", error);
                setError("error");
            }
        };

        loadLogs().catch(error => {
            console.error("Error in loadLogs:", error);
        });

        const interval = setInterval(fetchLogs, 60000); // Update logs every minute
        return () => clearInterval(interval);
    }, [battleId, token]);

    if (error) {
        return <p>Error loading logs: {error}</p>;
    }

    return (
        <div className={styles.logsContainer}>
            <h2>Logs</h2>
            <ul>
                {logs.map((log:any, index) => (
                    <li key={index}>{log.result} - <small>{new Date(log.timestamp).toLocaleString()}</small></li>
                ))}
            </ul>
        </div>
    );
};

export default Logs;