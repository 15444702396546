import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UserProfileProps {
    id: number,
    level: number,
    avatar: string
}
// Define the shape of the context state
interface GlobalContextProps {
    setUserId: (userId: string) => void;
    setUserProfile: (profile: any)  => void;

    userId: string;
    userProfile: UserProfileProps;
}

// Create the context
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

// Custom hook to use the context
export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};

// Provider component
export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const storedUserProfile = localStorage.getItem("userProfile");
    const initialUserProfile = storedUserProfile ? JSON.parse(storedUserProfile) : { id: 0, level: 0, avatar: 'avatar1' };

    const [userId, setUserId] = useState<string>('');
    const [userProfile, setUserProfile] = useState<UserProfileProps>(initialUserProfile);

    return (
        <GlobalContext.Provider value={{ userId, setUserId, userProfile, setUserProfile }}>
            {children}
        </GlobalContext.Provider>
    );
};